/* ClientForm.css */
.client-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20%;
    margin-top: 2%;
    flex-direction: column;
}

.client-form-intro {
    border-top: 7px solid #007bff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 5%;
}

.client-form {
    max-width: 850px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.client-form h2, .client-form h3 {
    margin-bottom: 30px;
    color: var(--accent);
}

.client-form p {
    color: #d3d3dd;
}

.form-margin {
    margin-left: 30px;
    margin-right: 30px;
}


.form-group {
    position: relative;
    margin-bottom: 40px;
    margin-right: 30px;
}


/* .paid-advertising .form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group textarea {
    padding: 15px 0px;
} */


.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group input[type="url"],
.form-group textarea {
    width: 60%;
    padding: 5px 2px;
    border: none;
    border-bottom: 2px solid #ccc;
    box-sizing: border-box;
    background-color: transparent;
    color: white;
    font-size: 20px;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group input[type="tel"]:focus,
.form-group input[type="url"]:focus,
.form-group textarea:focus {
    outline: none;
    border-bottom: 2px solid var(--primary-button);
}

.form-group label {
    position: absolute;
    top: 0;
    left: 0;
    color: #666;
    pointer-events: none;
    font-size: 18px;
    transition: all 0.3s ease;
}
.form-group input[type="text"]:focus ~ label,
.form-group input[type="email"]:focus ~ label,
.form-group input[type="tel"]:focus ~ label,
.form-group input[type="url"]:focus ~ label,
.form-group textarea:focus ~ label,
.form-group input[type="text"]:not(:placeholder-shown) ~ label,
.form-group input[type="email"]:not(:placeholder-shown) ~ label,
.form-group input[type="tel"]:not(:placeholder-shown) ~ label,
.form-group input[type="url"]:not(:placeholder-shown) ~ label,
.form-group textarea:not(:placeholder-shown) ~ label {
    top: -20px;
    left: 0;
    font-size: 16px;
    color: var(--accent);
}

.form-group textarea {
    resize: vertical;
}

.required {
    color: red;
    margin-left: 4px;
    font-size: 20px;
}

/* choices */
.choices ul, li {
    margin: 0;
    padding: 0;
    list-style: none; /* Remove default list styling */
}

.choices {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; /* Allow choices to wrap to next line */
    gap: 0px; /* Add space between the choices */
    margin-left: 30px;
}

.choices p {
    color: var(--text-secondary);
}

.choice {
    min-width: 520px; 
    margin: 10px;
}

.choice input[type="checkbox"] {
    display: none; /* Hide the checkbox */
}

/* Styling the label which appears as our button */
.choice label {
    padding: 10px 20px; /* Padding inside the labels */
    border: 2px solid var(--secondary-color); /* Blue border color */
    border-radius: 50px; 
    background-color: transparent; /* No background initially */
    color: var(--accent); /* Text color matches the border */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
    display: inline-block; /* Allow wrapping and spacing */
    max-width: 200px; /* Maximum width for each label */
    text-align: center; /* Center text within the label */
    margin: 0 5px; /* Add margin to ensure spacing on wrap */
    white-space: nowrap; /* Prevent text from wrapping */
}

/* Styling when checkbox is checked */
.choice input[type="checkbox"]:checked + label {
    background-color: var(--secondary-color); /* Blue background for selected state */
    color: white; /* White text when selected */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
}

/* seo */
.seo h3, .web-design h3, .paid-advertising h3 {
    color: var(--accent);
    margin-left: 30px;
    font-size: 1.5em;
}

.paid-advertising .form-group {
    width: 100%;
}

.borders {
    border: 1.5px solid var(--primary-color);
    border-radius: 16px;
    margin-bottom: 6%;
}

.borders p {
    color: var(--text-secondary);
    margin-left: 30px;
    margin-right: 30px;
}

/* Custom checkbox */
.choices-label {
    color: var(--text-secondary);
    margin-bottom: 15px;

}
.checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkbox-item {
    display: flex;
    align-items: center;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.checkbox-label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    color: var(--text-primary);
    user-select: none;
}

.checkbox-custom {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border-radius: 4px;
    border: 2px solid var(--secondary-color);
    transition: background-color 0.2s, border-color 0.2s;
}

input[type="checkbox"]:checked + .checkbox-label .checkbox-custom {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

input[type="checkbox"]:checked + .checkbox-label .checkbox-custom::after {
    content: '';
    position: absolute;
    left: 7px;
    top: 0px;
    width: 9px;
    height: 18px;
    border: solid var(--text-primary);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}


.other-text-wrapper {
    position: relative;
    display: block;
    width: calc(100% - 10px);
    margin-top: 5px;
}

.other-text {
    width: calc(100% - 10px);
    border: none;
    border-bottom: 2px solid transparent; /* Make the default border transparent */
    background-color: transparent;
    color: white;
    font-size: 16px;
    box-sizing: border-box; /* Include padding in the element's total width and height */
    overflow-wrap: break-word; /* Ensure long words break and wrap onto the next line */
    word-wrap: break-word; /* Legacy property for older browsers */
    white-space: normal;
}

.other-text:focus {
    outline: none;
}

.other-text-wrapper::after {
    content: '';
    position: absolute;
    bottom: -2px; /* Align with bottom of the input field */
    left: 5px; /* Slight offset to align with the text */
    width: 100%; /* Full width of the input field */
    background-color: var(--secondary-color); /* Use your highlight color */
    transition: all 0.3s ease;
    visibility: hidden; /* Hide by default */
}

.other-text:focus + .other-text-wrapper::after {
    visibility: visible; /* Make visible when input is focused */
}
    
.button-container {
    display: flex;
    max-width: 500px;
    margin-top: 20px;
    justify-content: flex-end;
}

/* .back-btn {
    padding: 0px 25px;
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 35px;    
    display: flex;
    align-items: center;
    justify-content: center;
} */
/* 
.back-btn:hover {
    color: var(--primary-button);
    border: 3px solid var(--primary-color);
} */

.button-container button {
    flex-grow: 1; /* Each button will take equal space */
    margin: 0 10px; /* Spacing between buttons */
    padding: 2px 0; /* Uniform padding for better touch targets */
    text-align: center; /* Center the text inside the button */
    border-radius: 50px; /* Mildly rounded corners for a modern look */
    transition: background-color 0.3s, color 0.3s; /* Smooth transitions for color and background */
    max-width: 100px;
}

.back-btn, .next-btn {
    font-size: 35px;
}

.submit-btn {
    font-size: 18px;
}

.button-container .back-btn,
.button-container .next-btn,
.button-container .submit-btn {
    background-color: transparent; /* Transparent background by default */
    color: #FFF; /* White text */
    border: 2px solid #007bff; /* Blue border from your theme */
}

.button-container .back-btn:hover,
.button-container .next-btn:hover,
.button-container .submit-btn:hover {
    background-color: #007bff; /* Blue background on hover */
    color: #FFF; /* Keep text white */
    cursor: pointer;
}

/* Adjust the responsiveness for smaller devices */

@media (max-width: 768px) {
    .client-form-container {
        margin-left: 0; /* Remove the left margin for smaller screens */
    }

    .form-group {
        margin-right: 0; /* Remove right margin for smaller screens */
    }

    .choice {
        min-width: 0; /* Remove the minimum width for flexibility */
        width: 100%; /* Full width on small screens */
    }
    
    .button-container {
        max-width: none; /* Remove the max-width to allow full width */
        width: 80%; /* Full width to use the available space */
    }
    
    .button-container button {
        font-size: 2.5rem; /* Increase font size for better readability */
        max-width: none; /* Remove the max-width to allow buttons to grow */
        margin: 0.5rem; /* Adjust margin for better spacing */
        padding: 0.5rem 1rem; /* Adjust padding for better touch targets */
    }

    .submit-btn {
        font-size: 5px;
    }
}

@media (max-width: 480px) {

    .form-group input[type="text"],
    .form-group input[type="email"],
    .form-group input[type="tel"],
    .form-group input[type="url"],
    .form-group textarea {
        font-size: 1.2rem; /* Further reduce font size for very small screens */
    }

    .form-group label {
        font-size: 1rem; /* Reduce font size of label for very small screens */
    }

    .button-container button {
        font-size: 2rem; /* Adjust font size for small screens */
    }

    .submit-btn {
        font-size: .9rem;
    }
}