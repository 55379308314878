/* src/App.css */
/* Add this at the top of your App.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Rounded:wght@400;700&display=swap');

:root {
  --background: #121212;
  --text-primary: #F8F8FF;
  --text-secondary: #A9A9A9;
  --primary-color: #0078D7;
  --primary-button: #007aff;
  --secondary-color: #8c4684;
  --accent:  #967BB6;
}
::-webkit-scrollbar {
  width: 6px; 
}
::-webkit-scrollbar-track {
  background: #2c2c2c; 
}
::-webkit-scrollbar-thumb {
  background: var(--primary-button); 
  border-radius: 3px; 
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

a:hover {
  cursor: pointer !important;
}

body {
  font-family: 'Roboto Rounded', Arial, sans-serif !important;
  color: var(--text-primary);
  background-color: var(--background);
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

* {
  font-family: 'Roboto Rounded', Arial, sans-serif !important;
}

.fixed-button {
  position: fixed;
  bottom: 7vh;
  font-size: 1rem; /* scalable unit */
  right: 0vw;
  z-index: 1000;
  padding: 0.75em 1.5em; /* scalable padding */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50px;
  transition: opacity 0.5s ease, transform 0.3s ease; /* Add transform for a hover effect */
}

.fixed-button:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
  transform: scale(1.05); /* Slightly scale up the button on hover */
  cursor: pointer;
}

.fixed-button.hide {
  /* opacity: 0; */
  /* visibility: hidden; */
  transition: opacity 0.5s ease;
}



.apple-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 15%; /* Use rem for consistent scaling */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo h1 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.logo img {
  width: 10rem; /* scalable unit */
  height: auto; /* maintain aspect ratio */
}

.logo {
  flex: 1; /* Give logo some flexibility to shrink and grow */
}

.nav-links {
  display: flex;
  gap: 1rem; /* scalable unit */
}

.nav-links a {
  text-decoration: none;
  font-size: 1rem; /* scalable unit */
  padding: 0.5rem 1rem; /* scalable unit */
}

.apple-navbar .cta {
  font-size: 1.45em;  
  font-weight: 600;
  color: var(--text-primary);  
  transition: background-color 0.3s, color 0.3s;
}

.apple-navbar .cta:hover {
  color: var(--primary-color);  
  cursor: pointer;
}

/* Media Query for Tablets and Desktops */
@media (max-width: 767px) {
  .apple-navbar {
    flex-direction: column; /* Stack logo and links on top of each other */
    padding: 1rem 2%; /* Reduce padding */
  }

  .logo img {
      width: 8rem; /* Slightly reduce logo size on smaller screens */
  }

  .nav-links {
      width: 100%; /* Full width for stacked nav links */
      justify-content: center; /* Center links when they are stacked */
      margin-top: 0.5rem; /* Add a little space between logo and links */
  }

  .logo h1 {
    font-size: 18px;  /* Slightly reduce the font size for mobile */
  }

  .apple-navbar .cta {
    font-size: 16px;  /* Slightly reduce the font size for mobile */
    padding: 8px 16px;  /* Adjust padding */
  }

  .fixed-button {
    font-size: 0.9rem; /* Reduce font size for smaller screens */
    padding: 0.7em 1.2em; /* Adjust padding */
    border-radius: 40px; /* Adjust border-radius for smaller screens */
    bottom: 5vh; /* Adjust vertical position */
  }
}

@media (max-width: 480px) {
  .logo img {
      width: 6rem; /* Further reduce logo size on very small screens */
  }

  .nav-links a {
      font-size: 0.9rem; /* Slightly reduce font size for links */
      padding: 0.4rem 0.8rem; /* Adjust padding */
  }

  .fixed-button {
    font-size: 0.8rem; /* Reduce font size for mobile screens */
    padding: 0.6em 1em; /* Adjust padding */
    border-radius: 30px; /* Adjust border-radius for mobile screens */
    bottom: 10vh; /* Give more space from the bottom */
    right: 5vw; /* Give some space from the right edge */
  }
}
