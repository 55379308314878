#appointment {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 60px 5%;
    background: var(--background);
    border-radius: 10px;
    gap: 30px;
    margin-top: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.calendly-embed {
    flex: 1;
    padding-right: 3%;
}

.appointment-info {
    flex: 1;
    padding-left: 3%;
    color: var(--text-primary);
}

.appointment-info h2 {
    font-size: 2.4rem;
    margin-bottom: 25px;
    color: var(--secondary-color);
}

.appointment-info p {
    font-size: 1.3rem;
    margin-bottom: 25px;
    line-height: 1.6;
}

.appointment-info h3 {
    font-size: 1.7rem;
    margin-top: 35px;
    margin-bottom: 20px;
    color: var(--text-primary);
}

.appointment-info ul {
    list-style-type: none;
    padding-left: 0;
}

.appointment-info li {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: var(--text-secondary);
}

.bold-text {
    font-weight: bolder;
    padding: 0;
    color: var(--text-primary);
}

.bullet-icon {
    color: var(--primary-button);
    margin-right: 12px;
    font-size: 1.3rem;
    transition: transform 0.3s;
}

.bullet-icon:hover {
    transform: scale(1.1); 
}

/* Mobile responsiveness */
@media (max-width: 767px) {
    #appointment {
        flex-direction: column;
    }

    .calendly-embed, .appointment-info {
        padding: 0;
        width: 100%;
    }

    .calendly-embed {
        margin-bottom: 25px;
    }
}
