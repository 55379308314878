/* src/Home.css */
.apple-home {
    display: flex;
    justify-content: center;
    align-items: center; /* Uncomment this to vertically center the content */
    margin-top: 5%;
    height: 80vh; /* Increased height for better vertical space */
    background-color: var(--background); /* Ensure this variable is defined in your root CSS */
}

.content-wrapper {
    display: flex;
    flex-direction: column; /* Stack elements vertically on smaller screens */
    max-width: 1200px;
    width: 95%; /* Use a percentage width for responsiveness */
    margin: 0 auto;
    padding: 20px; /* Add some padding */
}

.text-content,
.image-content {
    width: 100%; /* Full width on small screens */
}

.apple-home h1 {
    font-size: 2.5rem; /* Smaller font size for smaller screens */
    margin-bottom: 20px;
}

.apple-home p {
    font-size: 1.4rem; /* Adjust font size */
    margin-bottom: 20px;
    color: var(--text-secondary); /* Ensure this variable is defined or replace it */
}

.image-content img {
    width: 70%; /* Full width images */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .apple-home {
        margin-top: 0%;
    }
    
    .content-wrapper {
        flex-direction: row; /* Horizontal layout on wider screens */
    }

    .text-content {
        flex: 0.7; /* 70% width */
        padding-right: 20px; /* Add space between text and image */
    }

    .image-content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .apple-home h1 {
        font-size: 3.5rem; /* Larger font size for larger screens */
    }

    .apple-home p {
        font-size: 1.6rem;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .content-wrapper {
        width: 90%; /* Slightly reduce width */
        margin-top: 2%; /* Adjust margin */
    }

    .image-content img {
        scale: 1.25; /* Scale up images for larger displays */
    }
}
