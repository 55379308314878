#team-section {
    position: relative; 
    z-index: 2; /* Ensures this section overlays the previous section */
    padding: 100px 5%;
    background-color: var(--text-secondary); /* Dark background color */
    display: flex;
    justify-content: space-between;
    align-items: center;
    clip-path: polygon(
        0% 2%, 
        5% 0%, 
        /* 10% 2%, */
        15% 2%, 
        /* 20% 2%, 
        30% 0%, 
        35% .5%, 
        40% 2%,
        45% .5%,  */
        50% 2%, 
        55% .5%, 
        /* 60% 2%, 
        65% .5%, 
        70% 0%,  */
        75% 1%, 
        /* 80% 1.5%, */
        85% .5%,  
        90% 0%, 
        95% 1.5%, 
        100% 1%, 
        100% .5%, 
        100% 98%, 
        95% 99%, 
        90% 98%, 
        85% 96%, 
        80% 96%, 
        /* 75% 98%,  */
        70% 98%, 
        65% 98%, 
        60% 98%, 
        55% 98%, 
        50% 98%, 
        /* 45% 98%,  */
        40% 95%, 
        /* 35% 91%,  */
        /* 30% 91%,  */
        25% 98%, 
        /* 20% 95%,  */
        /* 15% 94%,  */
        10% 98%, 
        5% 98%, 
        0% 99%
    );
}

.team-intro {
    flex: 1;
    padding-right: 50px;
}

.team-intro h2, h3 {
    color: var(--background); /* White text for contrast against the dark background */
}

.member h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
}

.team-intro h2 {
    font-size: 3rem;
    font-weight: 900;
    color: var(--primary-button);
}

.member, .team-intro p {
    font-size: 1.4rem;
    color: var(--background);
    font-weight: 500;
}

.member p {
    color: var(--primary-color);
    margin: 0;
    max-width: 250px;

}

.team-photos {
    flex: 1;
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: space-between; /* Space out the members */
    gap: 10px;
}

.member {
    flex: 0 0 calc(50% - 10px); /* Take up half the width minus the gap */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.member-image {
    width: 140px; /* Reduced size */
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 0px; /* Reduced gap */
    border: 2px solid #007AFF;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.member-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
}

.member-image:hover img {
    transform: scale(1.05);
}

/* Mobile responsiveness */
@media (max-width: 767px) {
    #team-section {
        flex-direction: column;
        gap: 50px;
    }

    .team-intro {
        padding-right: 0;
        text-align: center;
    }

    .team-photos {
        flex-direction: row;
        justify-content: space-between;
    }

    .member {
        flex: 0 0 100%; /* Take up full width on mobile */
    }
}
