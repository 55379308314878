/* src/css/About.css */
.apple-about {
    padding: 100px 5%;
    background-color: var(--background);
    /* text-align: center; */
    position: relative;
}

.philosophy-wrapper {
    max-width: 900px;
    margin: 0 auto 80px;
    padding: 40px;
    border-top: 2px solid var(--text-secondary);
    border-bottom: 2px solid var(--text-secondary);
}

.philosophy-wrapper h4 {
    font-size: 1.3rem;
}

.philosophy-wrapper p {
    letter-spacing: 0.5px;
}


.apple-about h3 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: bold;
    color: var(--secondary-color);
}

.apple-about p {
    font-size: 1.5rem;
    line-height: 1.4;
    margin-bottom: 20px;
    color: var(--text-secondary);
}


.cta-button {
    display: inline-block;
    margin-top: 40px;
    padding: 20px 50px;
    background-color: var(--primary-button);
    color: var(--text-primary);
    border-radius: 100px; 
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
    text-align: center;
}

.cta-main {
    display: block;
    font-size: 1.3rem;
    font-weight: bold;
}

.cta-sub {
    display: block;
    font-size: 0.9rem;
    opacity: .7;
    margin-top: 10px;
}

.cta-button:hover {
    transform: translateY(-5px);
    background-color: var(--primary-color);
}

/* Mobile responsiveness */
@media (max-width: 767px) {
    .apple-about {
        padding: 50px 5%;
    }

    .apple-about h2 {
        font-size: 2rem;
    }

    .apple-about p {
        font-size: 1rem;
    }
}
