/* src/css/Footer.css */

#footer-section {
    background-color: var(--background);
    padding: 50px 5%;
    /* font-family: 'San Francisco', Arial, sans-serif; */
    background-color: #2c2c2c;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
    clip-path: polygon(
        0% 1%, 
        5% .5%, 
        10% 0%,
        15% .5%, 
        20% 1%, 
        30% 0%, 
        35% .5%, 
        40% 1%,
        45% .5%, 
        50% 0%, 
        55% .5%, 
        60% 1%, 
        65% .5%, 
        70% 0%, 
        75% .5%, 
        80% 1%,
        85% .5%,  
        90% 0%, 
        95% .5%, 
        100% 1%, 
        100% .5%, 
        100% 100%,  
        0% 100%
    );
}

#footer-section a {
    color: var(--primary-button);
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s ease;
}

#footer-section a:hover {
    color: var(--primary-color);
}

.inquiry-section h3 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--text-primary);
}

.inquiry-section p {
    font-size: 1.3rem;
    margin-bottom: 20px;
    color: var(--text-secondary);
}

.inquiry-section a {
    font-size: 1.3rem;
}

.call-section .cta-button {
    display: inline-block;
    padding: 10px 50px;
    border-radius: 50px;
    transition: background-color 0.3s ease;
}

.cta-main {
    font-size: 1.5rem;
    color: #fff;
}

.cta-sub {
    display: block;
    font-size: 1rem;
    color: #f9f9f9;
}

.legal-section .legal-links {
    font-size: 1rem;
}

/* Mobile responsiveness */
@media (max-width: 767px) {
    #footer-section {
        padding: 30px 3%;
    }

    .inquiry-section h3, .inquiry-section p, .call-section .cta-button, .legal-section .legal-links {
        margin: 10px 0;
    }

    .call-section .cta-button {
        padding: 8px 16px;
    }
}
