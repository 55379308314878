.trusted-companies {
    position: relative;
    width: 100%;
    padding: 50px 0;
    background-color: var(--background); 
    text-align: center;
    position: relative;
    z-index: 1;
}

.trusted-companies h2 {
    font-size: 3rem;
    margin-bottom: 30px;
    color: var(--text-primary);
}

.companies-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; 
    gap: 40px; 
    max-width: 1200px;
    margin: 0 auto;
}

.company {
    position: relative; /* Set to relative to allow absolute positioning of child elements */
    display: flex;
    flex-direction: column; 
    align-items: center; 
    height: 200px; 
    padding-bottom: 20px; /* Space for the company name */
}

.company-logo {
    width: 300px;
    height: auto;
    transition: transform 0.3s;
    filter: grayscale(100%) drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.5));

}

.company-logo:hover {
    transform: scale(1.1);
    filter: grayscale(0%) drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.5));

}

.company-name {
    position: absolute; /* Absolute positioning */
    bottom: 0; /* Align to the bottom of the .company container */
    font-size: 1.2rem;
    font-weight: 500;
    color: #555;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .trusted-companies {
        padding: 30px 0;
    }

    h2 {
        font-size: 1.8rem;
    }

    .company-logo {
        width: 120px;
    }

    .company {
        height: 180px; 
    }
}
