#offer-section {
    position: relative; 
    z-index: 2; /* Ensures this section overlays the previous section */
    padding: 100px 5%;
    background-color: #2a2a2a; /* Dark background color */
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    clip-path: polygon(
        0% 4%, 
        /* 5% 2%,  */
        /* 10% 2%, */
        15% .5%, 
        /* 20% 2%, 
        30% 0%, 
        35% .5%, 
        40% 2%,
        45% .5%,  */
        50% 2%, 
        55% .5%, 
        /* 60% 2%, 
        65% .5%, 
        70% 0%,  */
        75% 1%, 
        80% 1.5%,
        85% .5%,  
        90% 0%, 
        95% 1.5%, 
        100% 1%, 
        100% .5%, 
        100% 95%, 
        95% 95%, 
        90% 94%, 
        85% 93%, 
        80% 94%, 
        75% 95%, 
        70% 96%, 
        65% 97%, 
        60% 99%, 
        55% 97%, 
        50% 98%, 
        45% 98%, 
        40% 98%, 
        35% 98%, 
        30% 98%, 
        25% 97%, 
        20% 97%, 
        15% 98%, 
        10% 98%, 
        5% 98%, 
        0% 99%
    );
}

.offer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #F8F8FF;
    background-color: #121212;
    position: relative;
}

.offer-center {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    margin: auto;
    padding: 20px;
}

.offer-text,
.offer-image {
    width: 100%; 
    box-sizing: border-box;
}

.offer-image {
    position: relative;
    z-index: 2;
}

.offer-text {
    margin-bottom: 40px;
}

.offer-image img {
    width: 100%; /* Make image responsive */
    height: auto;
    display: block; /* Removes bottom space under the image */
    position: relative;
    z-index: 2;
}

.offer-bubble {
    position: absolute;
    top: -25%;
    left: -10%;
    right: -20%;
    bottom: -30%;
    background: rgba(194, 194, 194, 0.2); 
    border-radius: 50%;
    animation: liquidAnimation 20s infinite ease-in-out;
    z-index: 0; 
  }

  @keyframes liquidAnimation {
    0%, 100% {
      transform: translateY(-10%) scale(1.05) rotate(0deg);
      border-radius: 45% 55% 60% 40% / 55% 60% 40% 45%;
    }
    25% {
      transform: translateY(10%) scale(0.95) rotate(15deg);
      border-radius: 55% 45% 35% 65% / 45% 35% 65% 55%;
    }
    50% {
      transform: translateY(-5%) scale(1) rotate(-15deg);
      border-radius: 40% 60% 60% 40% / 60% 60% 40% 40%;
    }
    75% {
      transform: translateY(5%) scale(0.9) rotate(0deg);
      border-radius: 35% 65% 45% 55% / 65% 45% 55% 35%;
    }
  }

/* Media Query for Tablets and larger devices */
@media (min-width: 768px) {
    .offer-center {
        flex-direction: row; 
        justify-content: space-between; /* Add space between the text and the image */
        gap: 0; /* Remove the gap, we are using margin for spacing */
    }

    .offer-text,
    .offer-image {
        width: auto; /* Width can be auto to allow flex to control size */
        flex: 1; /* Flex grow */
        min-width: 300px; /* Minimum width */
    }

    .offer-text {
        margin-right: 60px; /* Add space to the right of the text */
        margin-bottom: 0; /* Reset bottom margin */
    }

    .offer-image {
        margin-left: 60px; /* Add space to the left of the image */
    }
}

/* Media Query for very large screens, if needed */
@media (min-width: 1200px) {
    .offer-center {
        padding: 40px;
    }
    /* Adjust margins for very large screens if necessary */
}


.offer-text h2 {
    font-size: 2.1rem;
    color: var(--text-secondary);
}

.offer-text h1 {
    font-size: 2.6rem;
    margin: 12px 0;
    position: relative; /* This positions the pseudo-element relative to the h1 */
    padding-left: 50px;
}

.offer-text h1::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: linear-gradient(145deg, var(--primary-color), var(--accent));
    border-radius: 50%; /* Makes it circular */
    box-shadow: 0 0 10px var(--primary-color); /* Glowing effect */
}

.offer-text p {
    font-size: 1.4rem;
    line-height: 1.6;
}

.offer-image img {
    width: 100%; /* Make image responsive */
    height: auto;
    display: block; /* Removes bottom space under the image */
}

/* Media Query for Tablets and larger devices */
@media (min-width: 768px) {
    .offer-container {
        justify-content: space-between;
    }

    .offer-text {
        order: 0; /* Reset order to default for larger screens */
    }

    .offer-image {
        order: 1; /* Image will be shown on the right on larger screens */
    }
}

/* Media Query for very large screens, if needed */
@media (min-width: 1200px) {
    .offer-container {
        padding: 40px;
    }
}
